import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Pond,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  ValveConnect,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  MultiColInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  InfoButton,
  VFDPumpControl,
  ToggleControl,
  SnomaxControl,
  AlarmReset,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

const componentMinWidth = '70px';

function PumpScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  if (!props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Booster">
            <InfoControlRow>
              <Info
                title="Suction Pressure"
                statistic={locationEquipment2?.Other?.ioValues?.suction_water_pressure?.value}
                label={locationEquipment2?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units}
              />
              <Info
                title="Discharge Pressure"
                statistic={locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.value}
                label={locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
              />
              <Info
                title="Discharge Flow"
                statistic={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.value}
                label={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
              />
              <Info
                title="Water Temperature"
                statistic={locationEquipment2?.Other?.ioValues?.water_temperature?.value}
                label={locationEquipment2?.Other?.ioValues?.water_temperature?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="Room Temperature"
                statistic={locationEquipment2?.Other?.ioValues?.room_temperature?.value}
                label={locationEquipment2?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              {/* <Info
                title="Fuel Level"
                statistic={locationEquipment2?.Other?.ioValues?.fuel_level?.value}
                label={locationEquipment2?.Other?.ioValues?.fuel_level?.ioValueType?.units}
              /> */}
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2])} // plcId 25: booster
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
            </InfoControlRow>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

PumpScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

PumpScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PumpScreen);
